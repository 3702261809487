import React from "react";
import Header from "../shared/Header";
import Footer from "../shared/Footer";
import Navigation from "../shared/Navigation";
import HelloWorld from "./components/HelloWorld";
import AboutMe from "./components/AboutMe";
import ThoughtBoard from "./components/ThoughtBoard";

const HomePage = () => {
  return (
    <div>
      <Header />
      <Navigation />
      <HelloWorld />
      <AboutMe />
      <ThoughtBoard />
      <Footer />
    </div>
  );
};

export default HomePage;
