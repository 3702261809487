import React, { useState } from "react";

const programmingCourses = [
  "Structure and Interpretation of Computer Programs, CS 61A",
  "Data Structures, CS 61B",
  "Machine Structures, CS 61C",
  "Discrete Mathematics and Probability Theory, CS70",
  "Linear Algebra and Differential Equations, Math 54",
  "Foundations of Data Science, Data 8",
  "Principles and Techniques of Data Science, Data 100",
  "User Interface Design and Development, CS 160",
  "Computer Security, CS 161",
  "Artificial Intelligence, CS 188",
  "Natural Language Processing, Info 159",
];

const philosophyCourses = [
  "Introduction to Logic, Philos 12A",
  "Social Choice Theory, Philos 144",
  "Philosophy of Mathematics, Philos 146",
];

const foothillCourses = [
  "Object Oriented Programming in Python, CS 3A",
  "Intermediate Software Design in Python, CS 3B",
  "JavaScript for Programmers, CS 22A",
  "Photoshop for Photographers, Phot 4A",
  "Calculus I, Math 1A",
  "Calculus II, Math 1B",
  "Calculus III, Math 1C",
  "Statistics, Math 10",
  "Principles of Business, Busi 22",
  "Principles of Macroeconomics, Econ 1A",
  "Principles of Microeconomics, Econ 1B",
  "Financial Accounting I, Actg 1A",
  "Financial Accounting II, Actg 1B",
  "Basic Nutrition for Sports and Fitness, Kins 9",
  "International Relations & World Politics, Poli 15",
  "Introduction to Philosophy, Phil 4",
  "Introduction to Sociology, Soc 1",
  "General Psychology, Psych 1",
  "Cultures, Civilizations, & Ideas, Humn 1",
  "Physical Geography, Geog 1",
  "Composition & Reading, Engl 1A",
  "Honors Composition, Critical Reading, & Thinking, Engl 1B",
  "Argumentative Writing & Critical Thinking, Engl 1C",
  "Tutor Training, PSE 61A",
];


const EducationSection = () => {
  const [expanded, toggleExpanded] = useState(false);

  return (
    <div>
      <h3 className="mb-3 mt-4">Education</h3>

      <div className="card mb-3">
        <div className="card-body">
          <div className="mx-3">
            <h4>University of California, Berkeley</h4>
            <div className="my-1" style={{ fontSize: "100%" }}>
              <b>BA in Computer Science – Class of 2023</b>
            </div>
            <hr />
            <p className="my-1 fst-italic">Programming & Math Courses</p>
            <ul>
              {programmingCourses.map((course, index) => (
                <li key={index}>{course}</li>
              ))}
            </ul>
            <p className="my-1 fst-italic">Philosophy Courses</p>
            <ul>
              {philosophyCourses.map((course, index) => (
                <li key={index}>{course}</li>
              ))}
            </ul>
            <p className="my-1 fst-italic">
              Supplemental Courses at Foothill College
            </p>
            <ul
              style={{
                maxHeight: expanded ? "1000px" : "70px",
                overflow: "hidden",
                transition: "max-height 0.5s ease-in-out",
              }}
            >
              {foothillCourses.map((course, index) => (
                <li key={index}>{course}</li>
              ))}
            </ul>
            <div
              className="my-1 btn-link"
              style={{ cursor: "pointer" }}
              onClick={() => toggleExpanded(!expanded)}
            >
              {expanded ? "Collapse" : "Expand"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationSection;
