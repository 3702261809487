import React from "react";

interface Position {
  date: string;
  title: string;
  descriptions: string[];
}

interface TeachingCardProps {
  link: string;
  organization: string;
  firstPosition: Position;
  secondPosition: Position;
}

const TeachingCard: React.FC<TeachingCardProps> = ({
  link,
  organization,
  firstPosition,
  secondPosition,
}) => {
  const renderPosition = (position: Position) => (
    <>
      <div>
        <strong>{position.date}</strong>
      </div>
      <i>{position.title}</i>
      <div className="mt-2">
        <ul className="list-group list-group-flush">
          {position.descriptions.map((description, index) => (
            <li className="list-group-item" key={index}>
              {description}
            </li>
          ))}
        </ul>
      </div>
    </>
  );

  return (
    <a
      href={link}
      style={{ textDecoration: "none", color: "inherit" }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="card mb-3">
        <div className="card-body">
          <div className="mx-3">
            <h4>{organization}</h4>
            {renderPosition(firstPosition)}
          </div>
          <div className="mx-3 my-2">{renderPosition(secondPosition)}</div>
        </div>
      </div>
    </a>
  );
};

export default TeachingCard;
