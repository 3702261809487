import React from "react";

interface SkillsCardProps {
  title?: string;
  subtitle?: string;
  skills: Array<string | { title: string; skills: string[] }>;
}

const SkillsCard = ({ title, subtitle, skills }: SkillsCardProps) => {
  return (
    <div className="mx-3">
      <h4>{title}</h4>
      {subtitle && <h6>{subtitle}</h6>}
      <ul>
        {skills.map((skill, index) => (
          <li key={index}>
            {typeof skill === "string" ? (
              skill
            ) : (
              <SkillsCard title={skill.title} skills={skill.skills} />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SkillsCard;
