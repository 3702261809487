import React from "react";
import TeachingCard from "../Cards/TeachingCard";

const TeachingSection = () => {
  return (
    <div className="col">
      <h3 className="mb-3">Teaching Experience</h3>

      <TeachingCard
        link="https://foothill.edu/tlc/"
        organization="Teaching & Learning Center, Foothill College"
        firstPosition={{
          date: "Sep 2020 – Aug 2021",
          title: "Drop-in Writing Tutor",
          descriptions: [
            "Tutored students during drop-in hours on essays, scholarships, and undergraduate/graduate school applications",
            "Worked one-on-one with students in the disciplines of English, Economics, Humanities, Anthropology, & Communication Studies",
            "Attended weekly professional development seminars with faculty",
          ],
        }}
        secondPosition={{
          date: "Sep 2020 – Dec 2020",
          title: "Embedded English 1A Tutor",
          descriptions: [
            "Embedded in a Fall 2020 quarter English 1A course to address students' learning needs",
            "Designed and hosted 3 workshops on argumentation methods and essay writing",
            "Held weekly office hours and private tutoring sessions for students",
          ],
        }}
      />

      <TeachingCard
        link="https://foothill.edu/torch/"
        organization="Pass the Torch, Foothill College"
        firstPosition={{
          date: "Jan 2021 – Aug 2021",
          title: "Math1A (Calculus I) Tutor",
          descriptions: [
            "Met twice weekly with one tutee to improve their performance in Math1A",
            'Covered limits, derivatives, and their applications using "Calculus: Early Transcendentals (2nd Edition)," by Briggs, Cochran, & Gillett',
          ],
        }}
        secondPosition={{
          date: "Sep 2020 – Dec 2020",
          title: "English 1A Tutor",
          descriptions: [
            "Met twice weekly with tutees to improve their performance in English 1A",
            'Covered rhetorical techniques from "They Say / I Say," by Birkenstein & Graff',
          ],
        }}
      />
    </div>
  );
};

export default TeachingSection;
