import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import HomePage from "./components/HomePage/HomePage";
import ProjectsPage from "./components/ProjectsPage/ProjectsPage";
import NotesPage from "./components/NotesPage/NotesPage";
import ResumePage from "./components/ResumePage/ResumePage";
import Analytics from "./analytics";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import CloudPage from "./components/CloudPage/CloudPage";

function App() {
  const measurementId = "G-D81J2Q6R6M";

  return (
    <Router>
      <Analytics measurementId={measurementId} />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/notes" element={<NotesPage />} />
        <Route path="/resume" element={<ResumePage />} />
        <Route path="/cloud" element={<CloudPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
