import React from "react";
import ReactGA from "react-ga";

interface NoteCardProps {
  title: string;
  subtitle: string;
  date: string;
  notesUrl: string;
  courseUrl: string;
  copyText: string;
}

const NoteCard = ({
  title,
  subtitle,
  date,
  notesUrl,
  courseUrl,
}: NoteCardProps) => {
  const handleButtonClick = () => {
    ReactGA.event({
      category: "Notes Page Button",
      action: "Click",
      label: `Notes: ${title}`,
    });
  };

  return (
    <div className="col-lg-3 mb-4 highlight">
      <div className="card" style={{ textAlign: "center" }}>
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <h6 className="card-title">{subtitle}</h6>
          <p className="card-text">{date}</p>
          <a
            href={notesUrl}
            className="btn btn-primary mx-1"
            target="_blank"
            rel="noreferrer"
            draggable="false"
            onClick={handleButtonClick}
          >
            Notes
          </a>
          <a
            href={courseUrl}
            className="btn btn-outline-secondary mx-1"
            target="_blank"
            rel="noreferrer"
            draggable="false"
          >
            Course
          </a>
        </div>
      </div>
    </div>
  );
};

export default NoteCard;
