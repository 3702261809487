import React from "react";
import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  text-align: center;
  max-width: 750px;
`;

const getMessage = (pathname: string) => {
  if (pathname.includes("/assets")) {
    return "The asset you're trying to access is not available.";
  }

  if (
    pathname === "/index" ||
    pathname === "/index/" ||
    pathname === "/home" ||
    pathname === "/home/"
  ) {
    return "If you're trying to access the Home Page, just use a backslash.";
  }

  return "";
};

const ErrorSection = () => {
  const location = useLocation();

  const message = getMessage(location.pathname);

  return (
    <Container className="container mt-4">
      <h1 style={{ textAlign: "center" }}>Error 404: Page Not Found</h1>
      <p>
        The requested URL <b>{location.pathname}</b> was not found on this
        server.
        {message && (
          <>
            <br />
            {message}
          </>
        )}
      </p>
      <Link to="/">
        <button className="btn btn-primary btn-md" type="button">
          Back to Home Page
        </button>
      </Link>
    </Container>
  );
};

export default ErrorSection;
