import React from "react";

const Footer = () => {
  return (
    <div className="container mt-4 unselectable">
      <footer className="pt-3 mt-3 text-muted border-top">
        <p>&copy; William Tholke 2024 </p>
      </footer>
    </div>
  );
};

export default Footer;
