import React from "react";
import ExamCard from "../Cards/ExamCard";

const exams = [
  {
    title: "Philos 12A",
    subtitle: "Introduction to Logic",
    date: "Spring 2022",
    examUrl: "../../../../assets/exams/Philos12A_Cheatsheet.pdf",
    buttonText: "Final",
    copyText: "TBD",
  },
  {
    title: "CS 188",
    subtitle: "Introduction to Artificial Intelligence",
    date: "Fall 2022",
    examUrl: "../../../../assets/exams/CS188_MT_Cheatsheet.pdf",
    buttonText: "Midterm 1",
    copyText: "TBD",
  },
];

const ExamSection = () => {
  return (
    <div className="container mt-4">
      <h3>Exam Cheat Sheets</h3>
      <p></p>
      <div className="row">
        {exams.map((exam) => (
          <ExamCard
            key={exam.title}
            title={exam.title}
            subtitle={exam.subtitle}
            date={exam.date}
            examUrl={exam.examUrl}
            buttonText={exam.buttonText}
            copyText={exam.copyText}
          />
        ))}
      </div>
    </div>
  );
};

export default ExamSection;
