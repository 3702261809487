import React from "react";

interface ExperienceCardProps {
  company: string;
  titleColor?: React.CSSProperties;
  location?: string;
  dateRange: string;
  position?: string;
  description?: string[];
  isEECS?: boolean;
  link?: string;
}

const ExperienceCard = ({
  company,
  titleColor,
  location,
  dateRange,
  position,
  description,
  isEECS,
  link,
}: ExperienceCardProps) => {
  return (
    <a
      href={link}
      style={{ textDecoration: "none", color: "inherit" }}
      target="_blank noopener noreferrer"
    >
      <div className="card mb-3">
        <div className="card-body">
          <div className="mx-3">
            {!isEECS && <h4 style={titleColor}>{company}</h4>}
            {isEECS && (
              <span>
                <h4>
                  <span style={{ color: "#003262" }}>UC Berkeley, </span>
                  <span style={{ color: "#04b2e2" }}>
                    Electrical Engineering{" "}
                  </span>
                  <span style={{ color: "#003262" }}>& </span>
                  <span style={{ color: "#ebab2a" }}>Computer Sciences </span>
                  <span style={{ color: "#003262" }}>{"("}</span>
                  <span style={{ color: "#04b2e2" }}>EE</span>
                  <span style={{ color: "#ebab2a" }}>CS</span>
                  <span style={{ color: "#003262" }}>{")"}</span>
                </h4>
              </span>
            )}
            <h6>{location ?? ""}</h6>
            <div>
              <strong>{dateRange}</strong>
            </div>
            <i>{position}</i>
          </div>
          <div className="mt-2">
            <ul className="list-group list-group-flush">
              {description ? description.map((item, index) => (
                <li key={index} className="list-group-item">
                  {item}
                </li>
              )) : <></>}
            </ul>
          </div>
        </div>
      </div>
    </a>
  );
};

export default ExperienceCard;