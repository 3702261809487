import React from "react";
import ExperienceCard from "../Cards/ExperienceCard";

const experience = [
  {
    company: "Daffy",
    titleColor: { color: "#3c4457" },
    location: "Los Altos, CA",
    dateRange: "Feb 2024 – Present",
    position: "Software Engineer",
    link: "https://www.daffy.org/",
  },
  {
    company: "Atlassian",
    titleColor: { color: "#0052cc" },
    location: "Mountain View, CA",
    dateRange: "Sep 2023 – Feb 2024",
    position: "Software Engineer",
    link: "https://www.atlassian.com/",
  },
  {
    company: "University of Chicago, Chameleon Cloud",
    titleColor: { color: "#810100" },
    location: "Chicago, Illinois (remote)",
    dateRange: "Oct 2022 – Dec 2022",
    position: "Research Intern",
    description: [
      "Worked with Team Nimbus to develop the Chameleon platform and improve the reproducibility of Computer Science systems research",
    ],
    link: "https://www.chameleoncloud.org/about/chameleon/",
  },
  {
    company: "Atlassian",
    titleColor: { color: "#0052cc" },
    location: "Mountain View, CA",
    dateRange: "May 2022 – Aug 2022",
    position: "Software Engineer Intern (Full Stack)",
    description: [
      "Built interactive modal to surface backend JQL queries used to populate Jira ticket queues in internal support platform Single Queue (SQ), drastically reducing trivial support requests from up to 800 engineers across 26 teams",
      "Feature led, built, and rolled out full stack JQL macros feature for SQ admins to create, delete, bulk import/export, and conveniently use macros in JQL queries to replace repetitive code with keys that are mapped to and interpreted as JQL",
      "Completely revamped all 12 pages in the SQ admin front-end for Q4 Innovation Week, implementing feature flags, error/success handling, and style repairs, and successfully proposed a new design standard now used in production",
      "Built bash script to boot the local dev environment from a cold start, which intelligently starts/monitors frontend and backend health endpoints, serves mock data to a local H2 database, and brings Docker containers online",
    ],
    link: "https://www.atlassian.com/",
  },
  {
    company: "UC Berkeley, Center for the Built Environment",
    titleColor: { color: "#748b9c" },
    location: "Wurster Hall, Berkeley",
    dateRange: "Sep 2021 – May 2022",
    position: "Research Apprentice",
    description: [
      "Worked with PhD Paul Raftery to model mass energy wasting in large commercial buildings due to piping losses and boiler inefficiency",
      "Gathered, processed, and analyzed data from heating hot water systems in existing buildings using R (Tidyverse) and other programming methodologies",
    ],
    link: "https://cbe.berkeley.edu/research/reducing-gas-consumption/",
  },
  {
    company: "UC Berkeley, Electrical Engineering & Computer Sciences (EECS)",
    location: "Berkeley, CA",
    dateRange: "Sep 2021 – Dec 2021",
    position: "Academic Intern (AI), CS 61A",
    description: [
      "Answered questions and provided conceptual support to UC Berkeley students during lab section in CS 61A: The Structure and Interpretation of Computer Programs",
    ],
    isEECS: true,
    link: "https://eecs.berkeley.edu/",
  },
  {
    company: "BirdEye",
    titleColor: { color: "#1b75d2" },
    location: "Palo Alto, CA",
    dateRange: "Jun 2021 – Jul 2021",
    position: "Software Engineering Intern",
    description: [
      "Designed Google Chrome extension using HTML, CSS, & the Node.js API puppeteer to scrape and format data in a downloadable JSON file from popular business review websites (Yelp, Capterra, etc.)",
    ],
    link: "https://birdeye.com/",
  },
];

const ExperienceSection = () => {
  return (
    <div>
      <h3 className="mb-3 mt-4">Professional Experience</h3>
      {experience.map((exp, index) => (
        <ExperienceCard key={index} {...exp} />
      ))}
    </div>
  );
};

export default ExperienceSection;
