import React from "react";


const ThoughtBoard = () => {
  return (
    <div className="container mt-4">
      <div className="container mt-4 p-5 mt-4 rounded-3 bg-secondary blue-gradient">
        <div className="container-fluid py-2" style={{ color: "white" }}>
          <h2 className="display-6 fw-bold">Thought board</h2>
          <p>[updated 05/08/23]</p>
          <div className="col-md-10 fs-5">
            <ul style={{ fontSize: "100%" }}>
              <li>
                Well, it's Monday of finals week. I've got two more papers
                to write–one on emergent dialogue systems, and the other on
                designing an AI system for social welfare. There's an exam as
                well as a project or two, of course ;'{")"}
              </li>
              <li>
                Tune in to my{" "}
                <a
                  href="https://open.spotify.com/playlist/69GoxOr6vzC683hp1lbFiR?si=8983825fb4914ff1"
                  style={{ color: "gold" }}
                >
                  Sunday Discovery
                </a>{" "}
                playlist on Spotify. I update it throughout the week with new
                music and delete all the old songs every Sunday.
              </li>
              <li>
                {"Check out the new "}
                <a
                  href="https://open.spotify.com/playlist/2h5DKqbRqopAh8zMSoBFM7?si=64bf1612680147e5"
                  style={{ color: "gold" }}
                >
                  House Discovery
                </a>{" "}
                and{" "}
                <a
                  href="https://open.spotify.com/playlist/1OZ6ib8aODLmK5lznLegsa?si=afdd29c36b304e93"
                  style={{ color: "gold" }}
                >
                  Monthly Discovery
                </a>
                {" playlists, recent editions to my Discovery collection."}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThoughtBoard;
