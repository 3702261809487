import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

interface AnalyticsProps {
  measurementId: string;
}

const Analytics = ({ measurementId }: AnalyticsProps) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(measurementId);
  }, [measurementId]);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null;
};

export default Analytics;
