import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import styled from "styled-components";
import me from "../../../assets/images/me.webp"

const HelloWorldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 0;
`;

const HelloWorldText = styled.div`
  flex: 1;
`;

const ImageContainer = styled.div`
  padding-left: 5px;
  padding-top: 10px;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;

  &.loaded {
    opacity: 1;
  }
`;

const ImageWrapper = styled.img.attrs(() => ({ className: "unselectable" }))`
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;

  @media (max-width: 992px) {
    display: none;
  }
`;

const handleContextMenu = (e: React.MouseEvent) => {
  e.preventDefault();
};


const HelloWorld = () => {
  const [imageLoaded, setImageLoaded] = useState(
    localStorage.getItem("imageLoaded") === "true" || false
  );

  const handleImageLoad = () => {
    setImageLoaded(true);
    localStorage.setItem("imageLoaded", "true");
  };

  const handleResumeButtonClick = () => {
    ReactGA.event({
      category: "Home Page Button",
      action: "Click",
      label: "Resume Page",
    });
  };

  const handleContactButtonClick = () => {
    ReactGA.event({
      category: "Home Page Button",
      action: "Click",
      label: "Contact Email",
    });
  };

  useEffect(() => {
    if (imageLoaded) {
      localStorage.setItem("imageLoaded", "true");
    }
  }, [imageLoaded]);

  return (
    <div className="container mt-4">
      <div className="container mt-4 p-5 bg-dark rounded-3">
        <div className="container-fluid py-5" style={{ color: "white" }}>
          <HelloWorldContainer>
            <HelloWorldText>
              <h1 className="display-5 fw-bold">Hello, World!</h1>
              <p className="col-md-11 fs-4">
                I'm currently building fintech software at
                {" "}
                <a
                    href="https://www.daffy.org/"
                    target="_blank"
                    style={{ color: "#63a7ff" }}
                    rel="noreferrer"
                >
                  Daffy
                </a>
                , where some of the top investors in Silicon Valley support our mission
                of making charitable giving a habit–Reid Hoffmann (LinkedIn),
                Dylan Field (Figma), Aaron Levie (Box), Ribbit Capital,
                Coinbase Ventures, and more. Prior to Daffy, I helped build innovative
                enterprise-grade cloud products at
                {" "}
                <a
                    href="https://www.atlassian.com/"
                    target="_blank"
                    style={{ color: "#63a7ff" }}
                    rel="noreferrer"
                >
                  Atlassian
                </a> after graduating from{" "}
                <span style={{ color: "#f7dd4a" }}>UC Berkeley</span> with a
                degree in{" "}
                <span style={{ color: "#f7dd4a" }}>Computer Science</span>{""}.

              </p>
              <Link to="/resume">
                <button
                  className="btn mx-1 btn-primary btn-lg"
                  type="button"
                  onClick={handleResumeButtonClick}
                >
                  Resume
                </button>
              </Link>
              <a href="mailto:willtholke@berkeley.edu?subject">
                <button
                  className="btn mx-1 btn-light btn-lg"
                  type="button"
                  onClick={handleContactButtonClick}
                >
                  Contact
                </button>
              </a>
            </HelloWorldText>
            <ImageContainer className={imageLoaded ? "loaded" : ""}>
              <ImageWrapper
                src={me}
                onContextMenu={handleContextMenu}
                onLoad={handleImageLoad}
                alt="William Tholke's headshot"
              />
            </ImageContainer>
          </HelloWorldContainer>
        </div>
      </div>
    </div>
  );
};

export default HelloWorld;
