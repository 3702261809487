import React from "react";
import AwardCard from "../Cards/AwardCard";

const awards = [
  {
    title: "UC Berkeley 2021-2022 Leadership Award",
    description: "Established in 1934, The Leadership Award is a one-year, merit-based scholarship that recognizes undergraduate students at UC Berkeley who demonstrate innovative, initiative-driven leadership impacting their academic, work, or community environments. Students compete for the scholarship every year, ensuring a diverse and dynamic annual cohort of leaders whose work is relevant and newly inspiring.",
    link: "https://alumni.berkeley.edu/community/scholarships/leadership-award"
  },
  {
    title: "Foothill STEM Division Achievement Award"
  },
  {
    title: "Foothill Gene and Jeannie Seelbach Memorial Scholarship"
  },
  {
    title: "Foothill Academic Achievement Scholarship"
  },
  {
    title: "Foothill Pass the Torch Peer Mentoring Scholarship"
  }
]

const AwardSection = () => {
  return (
    <div>
      <h3 className="mb-3 mt-4">Awards</h3>
      {awards.map((exp, index) => (
        <AwardCard key={index} {...exp} />
      ))}
    </div>
  );
};

export default AwardSection;
