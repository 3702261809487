import React from "react";
import ReactGA from "react-ga";

interface ExamCardProps {
  title: string;
  subtitle: string;
  date: string;
  examUrl: string;
  buttonText: string;
  copyText: string;
}

const ExamCard = ({
  title,
  subtitle,
  date,
  examUrl,
  buttonText,
  copyText,
}: ExamCardProps) => {
  const handleButtonClick = () => {
    ReactGA.event({
      category: "Notes Page Button",
      action: "Click",
      label: `Exam: ${title}, ${buttonText}`,
    });
  };

  return (
    <div className="col-lg-3 mb-4 highlight">
      <div className="card" style={{ textAlign: "center" }}>
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <h6 className="card-title">{subtitle}</h6>
          <p className="card-text">{date}</p>
          <a
            href={examUrl}
            className="btn btn-primary mx-1"
            target="_blank"
            rel="noopener noreferrer"
            draggable="false"
            onClick={handleButtonClick}
          >
            {buttonText}
          </a>

          {/* Copy button */}
          {/* <pre hidden id={`notescell-${title}`}>
            {copyText}
          </pre>
          <button
            className="copybtn o-tooltip--left"
            data-tooltip="Copy"
            data-clipboard-target={`#notescell-${title}`}
          >
            <img src="images/copybtn/copy-button.svg" alt="Copy to clipboard" />
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default ExamCard;
