import React from "react";
import ExperienceCard from "../Cards/ExperienceCard";

const communityExperience = [
  {
    company: "Cal Triathlon, UC Berkeley",
    dateRange: "August 2022 - Present",
    position: "General Member",
    description: [
      "Swimmin, runnin, and bikin"
    ],
  },
  {
    company: "Cal Cycling, UC Berkeley",
    dateRange: "April 2021 - May 2022",
    position: "General Member",
    description: [
      "Most aero rider on the team, by far (more aero than Nico & Vamsi combined)",
      "Proud owner of a 2021 Specialized Allez E5 Sport",
    ],
  }
];

const CommunitySection = () => {
  return (
    <div>
      <h3 className="mb-3 mt-4">Community Involvement</h3>
      {communityExperience.map((exp, index) => (
        <ExperienceCard key={index} {...exp} />
      ))}
    </div>
  );
};

export default CommunitySection;
