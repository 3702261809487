import React from "react";
import NoteCard from "../Cards/NoteCard";

const notes = [
  {
    title: "Data C100",
    subtitle: "Principles and Techniques of Data Science",
    date: "Spring 2022",
    notesUrl: "https://github.com/willtholke/Data100",
    courseUrl: "https://ds100.org/fa21/",
    copyText: "TBD",
  },
  {
    title: "Data C8",
    subtitle: "Foundations of Data Science",
    date: "Fall 2021",
    notesUrl: "https://github.com/willtholke/Data8",
    courseUrl: "http://www.data8.org/fa21/",
    copyText: "TBD",
  },
  {
    title: "CS 61B",
    subtitle: "Data Structures",
    date: "Fall 2021",
    notesUrl: "https://github.com/willtholke/CS61B",
    courseUrl: "https://inst.eecs.berkeley.edu/~cs61b/fa21/",
    copyText: "TBD",
  },
  {
    title: "CS 61A",
    subtitle: "The Structure and Interpretation of Computer Programs",
    date: "Summer 2021",
    notesUrl: "https://github.com/willtholke/CS61A",
    courseUrl: "https://cs61a.org/",
    copyText: "TBD",
  },
];

const NoteSection = () => {
  return (
    <div className="container mt-4">
      <h3>Notes: CS @ UC Berkeley</h3>
      <p className="lead">Written in Markdown, Updated Weekly</p>
      <div className="row">
        {notes.map((note) => (
          <NoteCard
            key={note.title}
            title={note.title}
            subtitle={note.subtitle}
            date={note.date}
            notesUrl={note.notesUrl}
            courseUrl={note.courseUrl}
            copyText={note.copyText}
          />
        ))}
      </div>
    </div>
  );
};

export default NoteSection;
