import React from "react";
import SkillsCard from "../Cards/SkillsCard";

const SkillsSection: React.FC = () => {
  const programmingLanguages = [
    "JavaScript & TypeScript",
    "HTML & CSS",
    "Python",
    "C",
    "Assembly",
    "Java",
    "SQL",
    "R (functional language)",
  ];

  const industryTechnologies = [
    {
      title: "Industry Technologies",
      subtitle: "Full stack web development",
      skills: [
        "React.js (and in TypeScript)",
        "GraphQL & Apollo GraphQL",
        "Java Spring Boot",
        "Bootstrap",
        "PostgreSQL, MongoDB",
        "LaunchDarkly",
        "Docker containerization & deployment",
        "Deploying to EC2",
        "System design",
        "Shell scripting",
      ],
    },
    {
      subtitle: "Data visualization and cleaning",
      skills: [
        "pandas, datascience 0.15.7 [Python]",
        "Matplotlib, seaborn, plotly [Python]",
        "tidyverse [R]",
      ],
    },
    {
      subtitle: "Project development",
      skills: [
        "Git, GitHub, Bitbucket, version control",
        "Jira, Trello, Confluence, agile development",
        "Feature leading, rollouts, user acceptance testing (UAT)",
      ],
    },
    {
      subtitle: "Academic & Other",
      skills: ["LaTeX", "Overleaf", "Adobe Photoshop & After Effects"],
    },
  ];

  return (
    <div>
      <h3 className="mb-3">Skills</h3>
      <div className="card mb-3">
        <div className="card-body">
          <SkillsCard
            title="Programming Languages"
            skills={programmingLanguages}
          />
        </div>
      </div>

      <div className="card mb-3">
        <div className="card-body">
          {industryTechnologies.map((tech, index) => (
            <SkillsCard
              key={index}
              title={tech.title}
              subtitle={tech.subtitle}
              skills={tech.skills}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SkillsSection;
