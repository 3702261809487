import React from "react";

interface AwardCardProps {
  title: string;
  description?: string;
  link?: string;
}

const AwardCard = ({ title, description, link }: AwardCardProps) => {
  return (
    <a
      href={link}
      style={{ textDecoration: "none", color: "inherit" }}
      target="_blank noopener noreferrer"
    >
      <div className="card mb-3">
        <div className="card-body">
          <div className="mx-3">
            <strong>{title}</strong>
            {description && (
              <div className="mt-1">
                <i>{description}</i>
              </div>
            )}
          </div>
        </div>
      </div>
    </a>
  );
};

export default AwardCard;
